<template>
    <div></div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import errorMessages from '@/constants/errorMessages';
import routesStateConstant from '@/constants/routes';
import EdispoError from '@/services/errors/EdispoError.model';

const fileName = 'ImplicitCallbackPage.vue';

export default {
    name: 'ImplicitCallback',

    computed: {
        ...mapGetters(['fatalError']),
    },

    async beforeMount() {
        try {
            await this.$auth.handleLoginRedirect();
        } catch (error) {
            const authentError = new EdispoError({
                title: errorMessages.OKTA.TITLE,
                message: errorMessages.OKTA.MESSAGE.toParamString(error),
                fileName,
                stack: `Erreur d'authentification: ${error}`,
            });
            const solution = {
                title: 'Réessayer',
                action: this.emptyCache,
            };
            this.addFatalError({
                error: authentError,
                solution,
                fileName,
            });
        }
    },

    methods: {
        ...mapMutations(['addFatalError', 'removeFatalError']),

        emptyCache() {
            localStorage.removeItem('okta-cache-storage');
            localStorage.removeItem('okta-token-storage');
            sessionStorage.removeItem('okta-token-storage');
            sessionStorage.removeItem('okta-cache-storage');
            this.removeFatalError(this.fatalError.id);
            this.$router.push({
                path: routesStateConstant.CALENDAR.url,
            });
        },
    },
};
</script>
